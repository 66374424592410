import React from "react";
import { Row, Col } from "react-flexbox-grid";
// SCSS
import "./hero.scss";
//Assets
import HeroImage from '../../assets/hero/hero-image.png';
//Components
import Button from '../ui-components/button/button';
import Magnifier from "react-magnifier";

const hero = () => (
  <div className="hero" id="hero">
    <div className="wrapper">
      <Row>
        <Col md={12} lg={6}>
          <div className="hero-info">
            <h1 class="titletext" className="weight800 font60">Hello,</h1>
            <h1 class="titletext" className="weight800 font60">
              I'm Stephen Yan.
            </h1>
            <p className="font12">
            Designer passionate about branding & designing interfaces with a forward-thinking approach and a focus on tech startups.
            </p>
            <Button label="SEND MESSAGE" target={"contact"} />
          </div>
        </Col>
        <Col md={12} lg={6}>
          <div id="img-magnifier-container" className="hero-image">
            <Magnifier src={HeroImage} mgBorderWidth={0} mgShape="square" alt="hero"/>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default hero;
