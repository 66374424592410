import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./about.scss";
// Components
import TeamBox from './teamBox';
import TeamInfo from "./teamInfo";
import TeamInfor from "./tr";
import Title from "../ui-components/title/title";
// Assets
import Person01 from "../../assets/about/person01.png";
import Person02 from "../../assets/about/person02.png";

const about = () => (
  <div id="about">
    <div className="wrapper">
      <Title title="ABOUT ME" />
      <p className="font12">Coming from a graphic design background I understand the limits of the Aesthetic-Usability Effect. I learned that you cannot ignore usability and that aesthetics are served to only support and enhance the functionality and content of the design.<br></br><br></br>

Through my experience working with UX Designers I have learned how to draw the fine line between usability and aesthetics; every interface I design focuses on accessibility and usability foremost before visuals.<br></br><br></br>

My extensive experience in graphic design and branding greatly enhances my design ability and I am highly knowledgeable in interaction design, typography, and color theory. I also have over 4+ years of experience in graphic design. View my <a href="https://www.behance.net/Stephen">Behance</a> for more of my graphic design work.
<br></br>
<br></br>
<h3>My personal values:</h3>
<br></br>
- Innovation 
<br></br>
- Ambition
<br></br>
- Excellence
      </p>
      <Row>
        <Col md={12} lg={4}>
          <TeamBox avatar={Person02} name="Stephen Yan" job="Designer" />
        </Col>
        <Col md={12} lg={4}>
          <TeamInfo />
        </Col>
        <Col md={12} lg={4}>
          <TeamInfor />
        </Col>
      </Row>
    </div>
  </div>
);

export default about;
