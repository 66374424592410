import React from "react";
// SCSS
import "./teamInfo.scss";

const about = () => (
  <div className="team__info flex-center" >
  <div>
    <h4 className="font20 weight800">UI/UX Design</h4>
    <p className="font12 weight500">
    I love improving product experiences.
    <br></br>
    <br></br>
    <h2>Skills</h2>
    UX Research, UI Design, Visual Design, Interaction Design, Wireframing, Prototyping, Usability Testing, Information Architecture
    <br></br>
    <br></br>
    <h2>Tools</h2>
    Figma, Adobe XD, InVision, Usability Hub, WordPress, Agile/Waterfall
    </p>
  </div>
    </div>
);


export default about;
