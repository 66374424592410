import React from "react";
// SCSS
import "./tr.scss";

const about = () => (
  <div className="team__info flex-center" >
  <div>
    <h4 className="font20 weight800">Graphic Design</h4>
    <p className="font12 weight500">
    I love creating new products and brands.
    <br></br>
    <br></br>
    <h2>Skills</h2>
    Logo Design, Brand Identity Design, Brand Strategy, Product Strategy, Social Media Design, Advertisement Design, Packaging Design, Print Design
    <br></br>
    <br></br>
    <h2>Tools</h2>
        Illustrator, Photoshop, InDesign
    </p>
  </div>
    </div>
);


export default about;
